import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AppLogo from '../components/AppLogo';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import {loginSelector} from "../slices/login";
import {Checkbox, FormControlLabel, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PasswordInput from "../components/PasswordInput";
import PasswordValidator from "../components/PasswordValidator";
import api from "../helpers/api";
import {setNotification} from "../slices/notification";
import qs from "qs";
import urlHelper from "../helpers/urlHelper";
import {isAndroid, isIOS} from "react-device-detect";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import {isEmpty} from "../helpers/utils";
import PrimaryButton from "../components/CustomButton";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: theme.spacing(8, 0, 0, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    logo: {
        maxHeight: "60px",
        maxWidth: "75%"
    },
    app_logo: {
        width: "75%"
    }
}));

const ROLE_CREATOR = 2;

const ClaimFlow = ({location, history}) => {
    const theme = useTheme()
    const {user} = useSelector(loginSelector);

    const role = user?.roles?.find(r => r.role.role_id === ROLE_CREATOR);

    const [step, setStep] = useState(1);
    const classes = useStyles();
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);

    const dispatch = useDispatch();

    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const [showErrors, setShowErrors] = useState(false);

    const handlePasswordChange = (event) => {
        setIsPasswordMatch(event.target.value === confirmPassword);
        setPassword(event.target.value);
    };

    useEffect(() => {
        const userRole = user?.roles?.find(r => r.role.role_id === ROLE_CREATOR);
        if (userRole?.ambassador_type === 1 || userRole?.ambassador_type === 2) {
            setIsEmployee(true);
        }
    }, [user]);

    const handleConfirmPasswordChange = (event) => {
        setIsPasswordMatch(event.target.value === password);
        setConfirmPassword(event.target.value);
    };
    const getPasswordErrorMessage = () => {
        if (!isPasswordValid) {
            return "Password is not valid.";
        } else {
            return "";
        }
    }

    const executeUpdate = () => {
        const user_id = user.user_id;
        if (!user_id) {
            return;
        }

        setIsLoading(true);

        let params = {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
        }
        api.updateBasicData(user_id)(
            response => {
                setStep(2);
                setIsLoading(false);
            },
            error => {
                setIsLoading(false);
                dispatch(setNotification("Operation failed. Cannot process the request. Contact support@creatable.io for assistance.", 'error'))
            }
        )(params);
    }

    const deleteToken = () => {
        const {search} = window.location;
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams?.auth_token) {
            api.deleteShortTokenAuth(user.user_id)(
                response => {

                },
                error => {

                }
            )({
                auth_token: queryParams?.auth_token
            })
        }
    }

    const accountClaimed = () => {
        const user_id = user.user_id;
        const hash = role.client.hash;
        const role_id = role.role.role_id;
        if (!user_id || !hash || !role_id) {
            return;
        }

        let params = {
            claimable: false
        }
        api.updateUser(user_id, hash, role_id)(
            response => {
                setStep(3);
                setIsLoading(false);
                deleteToken();
            },
            error => {
                setIsLoading(false);
                dispatch(setNotification("Operation failed. Cannot process the request. Contact support@creatable.io for assistance.", 'error'))
            }
        )(params)
    }

    const setAccountPassword = () => {
        api.setPassword(user.user_id)(
            response => {
                accountClaimed();
            },
            error => {
                setIsLoading(false);
            }
        )({password: password})
    }

    const onClickContinue = () => {
        if (getIsContinueLooksDisabled()) {
            setShowErrors(true);
        } else if (step === 1) {
            setShowErrors(false);
            executeUpdate();
        } else if (step === 2) {
            setAccountPassword();
        }
    }

    const getIsContinueLooksDisabled = () => {
        if (step === 1) {
            return (isEmpty(firstName) || isEmpty(lastName) || !isTermsChecked);
        } else if (step === 2) {
            return (!isPasswordValid || !isPasswordMatch);
        }
    }

    const goToPage = () => {
        const {search} = window.location;
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams?.continue && urlHelper.isValidUrl(queryParams?.continue)) {
            const url = new URL(queryParams?.continue);
            if (urlHelper.isAllowedHost(url.host)) {
                window.location.href = queryParams?.continue;
            } else {
                window.location.href = "/";
            }
        } else {
            window.location.href = "/";
        }
    }
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
              className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>

                    <Box mt={2} mb={2} justifyContent="center" display="flex">

                        <AppLogo style={{maxWidth: "100%"}}/>
                    </Box>

                    {(step === 1 || step === 2) && <Typography variant="h5" align="center" gutterBottom>
                        {step === 1 ? "Enter your details" : 'Set a password'}
                    </Typography>}

                    {
                        step === 1 &&
                        <Box mt={4} mb={0}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField variant="outlined"
                                           required
                                           label={'First name'}
                                           error={showErrors && isEmpty(firstName)}
                                           defaultValue={firstName}
                                           onChange={(e) => setFirstName(e.target.value)}
                                           helperText={(showErrors && isEmpty(firstName)) ? 'First name is required' : ''}
                                           autoFocus
                                />
                            </FormControl>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField variant="outlined"
                                           required
                                           label={'Last name'}
                                           error={showErrors && isEmpty(lastName)}
                                           defaultValue={lastName}
                                           onChange={(e) => setLastName(e.target.value)}
                                           helperText={(showErrors && isEmpty(lastName)) ? 'Last name is required' : ''}
                                />
                            </FormControl>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField variant="outlined"
                                           disabled
                                           defaultValue={user.email}
                                           label={'Email address'}
                                />
                            </FormControl>
                            <Box paddingTop={1}>
                                <Divider style={{marginBottom: 16, marginTop: 8}}/>
                                <FormControlLabel
                                    control={<Checkbox style={{paddingTop: 0, color: (showErrors && !isTermsChecked ? theme.palette.error.main : null)}} disableRipple
                                                       onChange={e => setIsTermsChecked(e.target.checked)}
                                                       defaultValue={false}/>}
                                    label={<Typography
                                        style={{lineHeight: '1.3'}}
                                        component={'p'} variant={'caption'}>I agree to the <Link
                                        target={'_blank'}
                                        href={'https://www.creatable.io/creator-terms'}>terms and
                                        conditions</Link> and <Link
                                        target={'_blank'}
                                        href={'https://creatable.io/privacy'}>privacy</Link> policy</Typography>}/>
                            </Box>
                        </Box>
                    }

                    {
                        step === 2 &&
                        <Box mb={1}>
                            <FormControl fullWidth margin={"normal"}>
                                <PasswordInput error={(showErrors && !isPasswordValid)}
                                               errorText={getPasswordErrorMessage()}
                                               label={"Password"}
                                               onChange={handlePasswordChange}/>
                            </FormControl>
                            <FormControl fullWidth margin={"normal"}>
                                <PasswordInput error={showErrors && !isPasswordMatch}
                                               errorText={"Password and confirm password must match!"}
                                               label={"Confirm password"}
                                               onChange={handleConfirmPasswordChange}/>
                            </FormControl>
                            <PasswordValidator password={password} onValidate={setIsPasswordValid}/>
                        </Box>
                    }
                    {
                        (step === 1 || step === 2) &&
                        <Box pt={2} pb={0}>
                            <PrimaryButton disabled={isLoading}
                                           onClick={onClickContinue}
                                           looksDisabled={getIsContinueLooksDisabled()}
                                           fullWidth>
                                CONTINUE
                            </PrimaryButton>
                        </Box>
                    }

                    {
                        step === 3 &&
                        <Box>
                            <Typography variant={'h4'} align={'center'} gutterBottom>
                                You’re done!
                            </Typography>
                            <Typography align={'center'} gutterBottom>
                                That’s it, you can now access Creatable and get started.
                            </Typography>
                            <Box display={'flex'} justifyContent={'center'} flexWrap={'wrap'}>
                                {(isIOS || isAndroid) &&
                                    <Box py={1}><Typography gutterBottom>Be cool - use the mobile
                                        app!</Typography></Box>}
                                {isIOS && <a style={{display: 'flex'}}
                                             href="https://apps.apple.com/us/app/storefront-by-tvpage/id1518518652"
                                             className="apps-ios">
                                    <img
                                        style={{height: '75px'}}
                                        src="https://d33wubrfki0l68.cloudfront.net/8a2d00415725348aa48f368e797360a50aee43a2/4a10c/wp-content/themes/iambassador/img/apple-app-store.svg"
                                        alt="Creatable IOS app"/>
                                </a>}
                                {isAndroid &&
                                    <a href="https://play.google.com/store/apps/details?id=com.tvpage.storefront&amp;hl=en_US"
                                       className="apps-android">
                                        <img
                                            style={{height: '75px'}}
                                            src="https://d33wubrfki0l68.cloudfront.net/52be3a706c5c515f5f908b5762db9a61bf42c50b/12f71/wp-content/themes/iambassador/img/google-app-store.png"
                                            alt="Creatable Android app"/>
                                    </a>}
                            </Box>
                            <Box pt={2}>
                                <Button color={'primary'} variant={'contained'} fullWidth onClick={goToPage}
                                        style={{textTransform: 'unset'}}>
                                    {isEmployee ? "Take me in!" : "View campaign"}
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
                {isLoading && <LinearProgress/>}
            </Paper>
        </Grid>
    );

}

export default ClaimFlow;
