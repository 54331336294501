import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {isEmpty} from "../../helpers/utils";
import Box from "@material-ui/core/Box";
import React, {useEffect, useRef, useState} from "react";
import * as EmailValidator from "email-validator";
import Api from "../../helpers/api";
import useNotification from "../../helpers/useNotification";
import useOnboarding from "../../helpers/useOnboarding";
import PrimaryButton from "../CustomButton";

const BasicInfoStep = ({onSuccess}) => {
    const notification = useNotification();
    const {inProgress, setInProgress, setBasicInfo, firstName, lastName, email} = useOnboarding();

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();

    const [showErrors, setShowErrors] = useState(false);

    const [isEmailRegistered, setIsEmailRegistered] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);

    useEffect(() => {
        firstNameRef?.current?.focus();
    }, []);

    useEffect(() => {
        setIsEmailValid(EmailValidator.validate(email));
        setIsEmailRegistered(false);
    }, [email]);

    const getEmailHelperText = () => {
        if (isEmpty(email) && showErrors) {
            return "This field is required.";
        } else if (!isEmailValid && showErrors) {
            return "This email address is not valid.";
        } else if (isEmailRegistered && showErrors) {
            return "This email address is already in use!";
        } else {
            return "";
        }
    }

    const onClickSave = () => {
        if (isEmpty(firstName)) {
            setShowErrors(true);
            firstNameRef?.current?.focus();
        } else if (isEmpty(lastName)) {
            setShowErrors(true);
            lastNameRef?.current?.focus();
        } else if (!EmailValidator.validate(email) || isEmpty(email)) {
            setShowErrors(true);
            setIsEmailValid(false);
            emailRef?.current?.focus();
        } else {
            setInProgress(true);
            setIsEmailValid(true);
            setShowErrors(false);
            Api.verifyEmail()(
                ({data}) => {
                    setIsEmailRegistered(data.is_registered);
                    if (!data.is_registered) {
                        setShowErrors(false);
                        onSuccess();
                    } else {
                        setShowErrors(true);
                        emailRef && emailRef?.current?.focus();
                    }
                },
                (error) => notification.captureError(error)
            )({email}).then(() => setInProgress(false));
        }
    };

    const onPressEnter = (callback) => (e) => {
        if (e.key === 'Enter') {
            callback();
        }
    };

    const getButtonLooksDisabled = () => {
        return (isEmpty(email) || isEmpty(firstName) || isEmpty(lastName) || (showErrors && !EmailValidator.validate(email)) || isEmailRegistered);
    }

    return (
        <Box mt={2}>
            <FormControl fullWidth margin={"dense"}>
                <TextField label={"First name"} variant="outlined"
                           inputRef={firstNameRef}
                           onChange={(event) => setBasicInfo({firstName: event.target.value, lastName, email})}
                           error={isEmpty(firstName) && showErrors}
                           value={firstName}
                           autoFocus
                           disabled={inProgress}
                           onKeyUp={onPressEnter(() => lastNameRef?.current?.focus())}
                           helperText={isEmpty(firstName) && showErrors ? "This field is required." : ""}/>
            </FormControl>

            <FormControl fullWidth margin={"dense"}>
                <TextField label={"Last name"} variant="outlined"
                           inputRef={lastNameRef}
                           onChange={(event) => setBasicInfo({firstName, lastName: event.target.value, email})}
                           error={isEmpty(lastName) && showErrors}
                           value={lastName}
                           disabled={inProgress}
                           onKeyUp={onPressEnter(() => emailRef?.current?.focus())}
                           helperText={isEmpty(lastName) && showErrors ? "This field is required." : ""}/>
            </FormControl>

            <FormControl fullWidth margin={"dense"}>
                <TextField label={"Email"} variant="outlined"
                           inputRef={emailRef}
                           onChange={(event) => setBasicInfo({firstName, lastName, email: event.target.value})}
                           error={(isEmpty(email) || !isEmailValid || isEmailRegistered) && showErrors}
                           value={email}
                           disabled={inProgress}
                           onKeyUp={onPressEnter(onClickSave)}
                           helperText={getEmailHelperText()}/>
            </FormControl>

            <Box mt={1}>
                <PrimaryButton disabled={inProgress}
                               onClick={onClickSave}
                               looksDisabled={getButtonLooksDisabled()}
                               fullWidth>
                    Next
                </PrimaryButton>
            </Box>
        </Box>
    );
}

export default BasicInfoStep;