import environment from './environment';
import {DASHBOARDS} from "./roleHelper";

const proto = window.location.protocol;

const routes = {
    getUserServiceUrl: (path) => `${proto}//${environment.getUserServiceHost()}/v3/api/user${path}`,
    getUserServiceResourceUrl: (resourceURI) => `${proto}//${environment.getUserServiceHost()}${resourceURI}`,
    getLoginAppUrl: (path = "/") => `${proto}//${environment.getMerchantAppHost()}${path}`,
    geCreatorsAppUrl: (path) => `${proto}//${environment.getCreatorsAppHost()}${path}`,
    getBrandAppUrl: (clientHash, path='') => `${proto}//${environment.getBrandAppHost()}/${clientHash}${path}`,
    getAdminAppUrl: (clientHash, path) => `${proto}//${environment.getBrandAppHost()}/${clientHash}${path}`,
    getSearchServiceUrl: (path) => `${proto}//${environment.getUserServiceHost()}/v3/api/search${path}`,
    getSocialServiceUrl: (path = '') => `${proto}//${environment.getMerchantAppHost()}/v3/api/social${path}`,


    getApplicationStatusUrl: () => `${proto}//${environment.getMerchantAppHost()}/api/application/status`,

    getClientConfigBaseUrl: (path) => `${proto}//${environment.getClientServiceHost()}/v3/api/client${path}`,
    getClientConfigByHashUrl: (clientHash, full = true) => routes.getClientConfigBaseUrl(`/hash/${clientHash}?full=${full ? "true" : "false"}`),

    getOAuthLoginUrlByProvider: (provider) => routes.getUserServiceUrl(`/connect/${provider}/login`),
    getOAuthSignUpUrlByProvider: (provider) => routes.getUserServiceUrl(`/connect/${provider}/signup`),

    getForgotPasswordUrl: () => `/forgot-password`,
    getErrorUrl: () => `/error`,
    getLogoutUrl: () => `/logout`,
    getSignUpUrl: () => `/signup/new`,

    getDefaultCreatorsMarketplaceDashboardUrl: () => routes.geCreatorsAppUrl('/marketplace'),
    getDefaultBrandDashboardUrl: (clientHash) => routes.getBrandAppUrl(clientHash),
    getDefaultAdminDashboardUrl: (clientHash) => routes.getBrandAppUrl(clientHash, '/posts'),

    getInfluencerDashboardSetupUrl: () => routes.geCreatorsAppUrl("/marketplace/setup?is_signup=true"),

    getLoginUrl: () => '/',

    getUserServiceLogoutUrl: (skipRedirect = false) => routes.getUserServiceUrl(`/logout?skip_redirect=${skipRedirect ? "true" : "false"}`),
    getLoginSuccessUrl: () => `/login/success`,
    getOAuthSignUpSuccessUrl: () => `/signup/oauth-success`,
    getSignUpSuccessUrl: () => `/signup/success`,
    getAdminDashboardUrl: () => `${proto}//${environment.getAdminAppHost()}/`,
    getDashboardUrlByDashboardName: (dashboard, clientHash) => {
        if (dashboard === DASHBOARDS.DASHBOARD_CREATOR) {
            return routes.geCreatorsAppUrl(`/${clientHash}/campaigns`);
        } else if (dashboard === DASHBOARDS.DASHBOARD_BRAND) {
            return routes.getDefaultBrandDashboardUrl(clientHash);
        } else if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            return routes.getAdminDashboardUrl();
        } else {
            return null;
        }
    },
    getSsoErrorUrl: () => `/sso/error`,

    getAzureSSOUrl: () => `/sso/azure/:domain/authenticate`,
    getOktaSSOUrl: () => `/sso/okta/:domain/authenticate`,
    getSSOAuthenticateURL: (domain, jwt, nonce = null) => routes.getUserServiceUrl(`/sso/${domain}/authenticate?code=${jwt}${nonce ? `&nonce=${nonce}` : ""}`),
}

export default routes;
