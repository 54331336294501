import axios from 'axios';
import jsonpAdapter from "axios-jsonp";
import sentry from './sentry';

const request = {

    jsonp: url => async (onSuccess, onError) => {
        try {
            await axios({
                url: url,
                adapter: jsonpAdapter,
            }).then((response) => {
                onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
        }
    },

    head: url => async (onSuccess, onError) => {
        try {
            await axios.head(url).then((response) => {
                onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
        }
    },

    post: url => (onSuccess, onError) => async data => {
        try {
            return await axios({
                url: url,
                method: 'post',
                data,
                withCredentials: true,
            }).then((response) => {
                onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
            return Promise.reject(error);
        }
    },

    put: url => (onSuccess, onError) => async data => {
        try {
            await axios({
                url: url,
                method: 'put',
                data,
                withCredentials: true,
            }).then((response) => {
                onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
        }
    },

    get: url => async (onSuccess, onError) => {
        try {
            return await axios({
                url: url,
                method: 'get',
                withCredentials: true,
            }).then((response) => {
                onSuccess(response);
                return response;
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
                return error;
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
            return Promise.resolve(error);
        }
    },
    delete: url => (onSuccess, onError) => async data => {
        try {
            return await axios({
                url: url,
                method: 'delete',
                data,
                withCredentials: true,
            }).then((response) => {
                onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            onError(error);
        }
    }

}

export default request;