import Box from "@material-ui/core/Box";
// import LoadingApp from "../components/LoadingApp";
import React, {useEffect, useState} from "react";
import qs from "qs";
import api from "../helpers/api";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {AppBar, Container, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( theme=> ({
    internalCaptcha: {
        "& > div":{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));

const BrandOnboardingValidation = () =>{
    const [initialized, setInitialized] = useState(false);
    const [step, setStep] = useState(0);

    const classes = useStyles();

    const captchaSolver = (token, ekey)=>{
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if(params?.verification_code){
            api.client.emailVerification(params.verification_code, token, null)(
                response =>{
                    window.location.href = "/brand/account-activated";
                },
                error =>{
                    setStep(1)
                }
            )()
        }else{
            setStep(1);
        }
    };

    useEffect(() => {
        if(!initialized){
            setInitialized(true);
            const secretKey = window?.creatableKey;
            if(secretKey){
                const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
                if(params?.verification_code){
                    api.client.emailVerification(params.verification_code, null, secretKey)(
                        response =>{
                            window.location.href = "/brand/account-activated";
                        },
                        error =>{
                            setStep(1)
                        }
                    )()
                }else{
                    setStep(1);
                }
            }
        }
    }, [initialized]);

    return <Box>
        <Box bgcolor={'#000000'} mb={2}>
            <AppBar color={'transparent'} position={'relative'}>
                <Toolbar>
                    <img height={'34px'} src={"https://sitecdn.tvpage.com/static/images/creatable_logo_white.svg"} alt={'Creatable'}/>
                </Toolbar>
            </AppBar>
        </Box>
        {step === 0 && <Box>
            <Container maxWidth={'xs'}>
                <Box className={classes.internalCaptcha}>
                    <HCaptcha sitekey={'cd5a093b-8427-45b1-b6c8-dc55de41329b'} onVerify={(token, ekey)=>captchaSolver(token, ekey)} />
                </Box>
            </Container>
        </Box>}
        { step === 1 && <Box>

            <Container maxWidth={'xs'}>
                <Box display={'flex'} justifyContent={'center'} pb={1}>
                    <ErrorOutlineIcon style={{width: '64px', height: '64px'}} />
                </Box>
                <Typography variant={'h5'} align={'center'}>Oops!</Typography>
                <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={1} pb={1}>
                    <Typography align={'center'}>
                        Something went wrong!
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Link href={'mailto:support@creatable.io'}>Contact us</Link>
                </Box>
            </Container>
        </Box>}
    </Box>
}
export default BrandOnboardingValidation;