import {createMuiTheme} from '@material-ui/core/styles';
import detectBrowserLanguage from 'detect-browser-language';
import {object as ObjectUtils} from '../helpers/utils';
import * as locales from '@material-ui/core/locale';

const getMuiLocaleFromBrowser = () => {
    const browserLocale = detectBrowserLanguage().split('-')[0];
    const muiLocale = Object.keys(locales).filter(l => l.slice(0, 2) === browserLocale)[0];
    if (locales.hasOwnProperty(muiLocale)) {
        return locales[muiLocale];
    } else {
        return locales.enUS;
    }
}

const createTheme = (options = {}) => {
    return createMuiTheme({
        palette: {
            primary: {
                main: ObjectUtils.get('primary_color', options, '#000'),
            },
            secondary: {
                main: '#000',
            },
            error: {
                main: '#e01a2b',
            },
            background: {
                default: '#fff',
            },
        },
        typography: {
            button: {
                textTransform: "none",
            },
        },
        overrides: {
            MuiOutlinedInput: {
                root: {
                    "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000"
                    },
                    borderRadius: '12px'
                },
                notchedOutline: {
                    "&.Mui-focused": {
                        borderColor: "red",
                        backgroundColor: "yellow",
                    }
                }
            },
            MuiInputLabel: {
                outlined: {
                    "&.Mui-focused": {
                        color: "#000",
                    }
                }
            },
            MuiLink: {
                root: {
                    color: "#000",
                    cursor: "pointer",
                },
                underlineHover: {
                    textDecoration: "underline",
                }
            },
            MuiButton: {
                root: {
                    borderRadius: '12px',
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: '20px'
                }
            },
        }
    }, getMuiLocaleFromBrowser());
};

export default createTheme;
