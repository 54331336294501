import Box from "@material-ui/core/Box";
import {AppBar, Container, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, {useRef, useState} from "react";
import Link from "@material-ui/core/Link";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {makeStyles} from "@material-ui/core/styles";
import {useFormik} from "formik";
import * as yup from 'yup';
import "yup-phone-lite";
import api from "../helpers/api";
import {useDispatch} from "react-redux";
import {setNotification} from "../slices/notification";

const useStyles = makeStyles( theme=> ({
    internalCaptcha: {
        "& > div":{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));

const shouldBeDisabled = (hcaptcha, formValid) =>{
    if(window?.creatableKey && formValid){
        return false;
    }
    if(!formValid || !hcaptcha){
        return true;
    }
};

const validationSchema = yup.object({
    first_name: yup.string('First name').required('Required field'),
    last_name: yup.string('Last name').required('Required field'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Required field'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Required field')
        .test(
            'isValidPass',
            'Must contain at least one uppercase, lowercase, number and symbol',
            (value, context) => {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasSymbol = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
                let validConditions = 0;
                const numberOfMustBeValidConditions = 4;
                const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol];
                conditions.forEach(condition => (condition ? validConditions++ : null));
                return validConditions >= numberOfMustBeValidConditions;

            },
        ),
    client_name: yup.string('Company name').required('Required field'),
    client_url: yup.string('Company website')
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Must be a valid URL (ex: https://www.example.com)'
        ).required('Required field'),
    phone: yup.string('Phone number').phone().required('Required field'),
    tell_me_more: yup.boolean(),
    walkthrough: yup.boolean()

});


const BrandOnboarding = () =>{
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(0);
    const [hcaptcha, setHcaptcha] = useState('');
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showCaptcha, setShowCaptcha] = useState(false);
    const captchaRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            client_name: '',
            client_url: '',
            phone: '',
            tell_me_more: false,
            walkthrough: false
        },
        isInitialValid: false,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    const onVerify = (token, ekey) =>{
        setHcaptcha(token);
    }

    const sendApplication = (resend= false, token=null)=>{
        let requestData = {...formik.values, hcaptcha};
        if(window?.creatableKey){
            requestData.security = window.creatableKey;
        }
        if(!requestData?.hcaptcha && token){
            requestData.hcaptcha = token;
        }
        api.client.onboarding()(
            response=>{
                setStep(1);
                setHcaptcha("");
                if(resend){
                    dispatch(setNotification('Email resent'));
                    setShowCaptcha(false);
                    captchaRef?.current?.resetCaptcha();
                }
            },
            error=>{

            }
        )(requestData);
        
    }

    const showCaptchaHandler = () =>{
        setShowCaptcha(true);
    }
    const onVerifyResend = (token, ekey) =>{
        setHcaptcha(token);
        setTimeout(()=>{
            sendApplication(true, token);
        },200)
    }

    return <Box>
        <Box bgcolor={'#000000'} mb={2}>
        <AppBar color={'transparent'} position={'relative'}>
            <Toolbar>
                <img height={'34px'} src={"https://sitecdn.tvpage.com/static/images/creatable_logo_white.svg"} alt={'Creatable'}/>
            </Toolbar>
        </AppBar>
        </Box>
        { step === 0 && <Container maxWidth={'xs'}>
            <Typography variant={'h5'} align={'center'}>Start a campaign</Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography align={'center'}>
                    Find and book creators to promote your products.
                </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
            <TextField variant={'outlined'} fullWidth name={'first_name'} label={'First name'} size={'small'} margin={'normal'}
                       value={formik.values.first_name}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                       helperText={formik.touched.first_name && formik.errors.first_name} />
            <TextField variant={'outlined'} fullWidth name={'last_name'} label={'Last name'} size={'small'} margin={'normal'}
                       value={formik.values.last_name}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                       helperText={formik.touched.last_name && formik.errors.last_name} />
            <TextField variant={'outlined'} type={'email'} fullWidth name={'email'} label={'Email address'} size={'small'} margin={'normal'}
                       value={formik.values.email}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.email && Boolean(formik.errors.email)}
                       helperText={formik.touched.email && formik.errors.email} />
            <TextField variant={'outlined'} type={showPassword ? 'text' : 'password'} fullWidth name={'password'} label={'Password'} size={'small'} margin={'normal'}
                       value={formik.values.password}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.password && Boolean(formik.errors.password)}
                       helperText={formik.touched.password && formik.errors.password}
               InputProps={{
                   endAdornment: (
                       <InputAdornment position="end">
                           <IconButton
                               aria-label="toggle password visibility"
                               onClick={()=>setShowPassword(value=>!value)}
                               onMouseDown={e=>e.preventDefault()}
                               edge="end"
                           >
                               {showPassword ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                       </InputAdornment>
                   )
               }}
            />
            <TextField variant={'outlined'} fullWidth name={'client_name'} label={'Company name'} size={'small'} margin={'normal'} value={formik.values.client_name}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                       helperText={formik.touched.client_name && formik.errors.client_name} />

            <TextField variant={'outlined'} fullWidth name={'client_url'} label={'Company website'} size={'small'} margin={'normal'} value={formik.values.client_url}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.client_url && Boolean(formik.errors.client_url)}
                       helperText={formik.touched.client_url && formik.errors.client_url} />

            <TextField variant={'outlined'} type={'tel'} fullWidth name={'phone'} label={'Phone number'} size={'small'} margin={'normal'} value={formik.values.phone}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.phone && Boolean(formik.errors.phone)}
                       helperText={formik.touched.phone && formik.errors.phone} />
            </form>

            <Box mt={2} mb={2} className={classes.internalCaptcha}>
                <HCaptcha style={{marginLeft: 'auto', marginRight: 'auto'}} sitekey={'cd5a093b-8427-45b1-b6c8-dc55de41329b'} onVerify={(token, ekey)=>onVerify(token, ekey)} />
            </Box>
            <Box>
                <Button onClick={()=>sendApplication()} disabled={shouldBeDisabled(hcaptcha, formik.isValid)} fullWidth color={'primary'} variant={'contained'}>
                    Continue
                </Button>
            </Box>
            <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={2}>
                <Typography component={'p'} variant={'caption'} align={'center'}>
                    By submitting this form, you agree to the <Link target={'_blank'} href={'https://www.creatable.io/terms'}>Terms of Service</Link> and <Link href={'https://www.creatable.io/privacy'}>Privacy Policy.</Link>
                </Typography>
            </Box>
            <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={2}>
                <Typography component={'p'} align={'center'}>
                    Already have an account?
                </Typography>
            </Box>
            <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={2} display={'flex'} justifyContent={'center'}>

                <Link style={{display:'flex', alignItems:'center'}} target={'_blank'} href={'/'}>Sign in <ArrowRightAltIcon fontSize={'small'} /></Link>

            </Box>

        </Container>}
        { step === 1 && <Container maxWidth={'xs'}>
            <Box display={'flex'} justifyContent={'center'} pb={1}>
                <MailOutlineIcon style={{width: '64px', height: '64px'}} />
            </Box>
            <Typography variant={'h5'} align={'center'}>Check your email</Typography>
            <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={1}>
                <Typography align={'center'}>
                    Click the link in your email to activate your account. If you don’t receive the email, try clicking “Resend email”
                </Typography>
            </Box>
            {showCaptcha && <Box mt={2} mb={2} className={classes.internalCaptcha}>
                <HCaptcha ref={captchaRef} style={{marginLeft: 'auto', marginRight: 'auto'}} sitekey={'cd5a093b-8427-45b1-b6c8-dc55de41329b'} onVerify={(token, ekey)=>onVerifyResend(token, ekey)} />
            </Box>}
            <Box pt={2} pb={2}>
                <Button onClick={()=>showCaptchaHandler()} disabled={!formik.isValid || showCaptcha} fullWidth color={'primary'} variant={'contained'}>
                    Resend email
                </Button>
            </Box>
            <Box>
                <Typography align={'center'}>Still having trouble?</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Link href={'mailto:support@creatable.io'}>Contact us</Link>
            </Box>
        </Container>}
    </Box>
}

export default BrandOnboarding;